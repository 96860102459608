define("ember-ckeditor/templates/components/ember-ckeditor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F30PRfUk",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"textarea\",null,[[\"value\",\"class\"],[[25,[\"value\"]],\"editor\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ckeditor/templates/components/ember-ckeditor.hbs"
    }
  });

  _exports.default = _default;
});