define('ember-cli-text-support-mixins/mixins/form-submission-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Pass in a closure function to fire after the form submit is triggered.
     * The function will receive three parameters: the first is the DOM event, the second is
     * `this` component, and the third is the jQuery wrapped `$form`.
     */
    afterSubmit: function afterSubmit() {
      // override accordingly
    },


    /**
     * Pass in a closure function to fire before the form submit is triggered.
     * The function will receive three parameters: the first is the DOM event, the second is
     * `this` component, and the third is the jQuery wrapped `$form`.
     */
    beforeSubmit: function beforeSubmit() {
      // override accordingly
    },


    /**
     *
     * @param form
     * @private
     */
    _submitForm: function _submitForm(form) {
      var submitButton = document.createElement('button');
      submitButton.style.display = 'none';
      submitButton.type = 'submit';
      try {
        form.appendChild(submitButton);
        submitButton.click();
      } finally {
        submitButton.remove();
      }
    },
    _triggerFormSubmission: function _triggerFormSubmission(afterSubmitFunction, beforeSubmitFunction, event, form) {
      // fire the before-submit action
      if (Ember.isPresent(beforeSubmitFunction)) {
        beforeSubmitFunction(event, this, form);
      }
      // submit the form
      this._submitForm(form);
      // fire the after-submit action
      if (Ember.isPresent(afterSubmitFunction)) {
        afterSubmitFunction(event, this, form);
      }
    }
  });
});