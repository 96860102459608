define('ember-cli-bootstrap3-popover/components/twbs-popover/content', ['exports', 'ember-cli-bootstrap3-popover/templates/components/twbs-popover/content'], function (exports, _content) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['hidden'],
    layout: _content.default,
    /**
     * Call the closure action passed to the `registerContent` property.  Invoking this closure should set the content
     * section of the popover.
     */
    _register: Ember.on('didInsertElement', function () {
      if (Ember.isPresent(this.get('register'))) {
        this.get('register')(this.$('.twbs-popover-content'));
      } else {
        Ember.Logger.warn('The `twbs-popover.content` component should have an action assigned to its `register` property.');
      }
    })
  });
});