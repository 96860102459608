define('ember-cli-text-support-mixins/mixins/ctrl-enter-submits-form', ['exports', 'ember-cli-text-support-mixins/mixins/closest-form', 'ember-cli-text-support-mixins/mixins/form-submission-utils'], function (exports, _closestForm, _formSubmissionUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_closestForm.default, _formSubmissionUtils.default, {
    /**
     * Pass in a closure function to fire after the form submit is triggered.
     * The function will receive three parameters: the first is the DOM event, the second is
     * `this` component, and the third is the jQuery wrapped `$form`.
     */
    afterCtrlEnterSubmitAction: Ember.computed.deprecatingAlias('afterSubmit', {
      id: 'mixin.ctrl-enter-submits-form.deprecate-after-ctrl-enter-submit-action',
      until: '2.1.0'
    }),

    /**
     * Pass in a closure function to fire before the form submit is triggered.
     * The function will receive three parameters: the first is the DOM event, the second is
     * `this` component, and the third is the jQuery wrapped `$form`.
     */
    beforeCtrlEnterSubmitAction: Ember.computed.deprecatingAlias('afterSubmit', {
      id: 'mixin.ctrl-enter-submits-form.deprecate-after-ctrl-enter-submit-action',
      until: '2.1.0'
    }),

    /**
     * When set to `true`, CTRL+ENTER will attempt to submit the nearest form.  Set this to `false` if you do not
     * want this behaviour.  Default value is `false`.
     */
    'ctrlEnterSubmitsForm?': false,

    /**
     * Immediately prevent this ENTER/RETURN key from bubbling.
     * @param event the keyboard event.
     */
    insertNewline: function insertNewline(event) {
      this._super.apply(this, arguments);

      if (this.get('ctrlEnterSubmitsForm?') && event.ctrlKey) {
        Ember.run.once(this, this._triggerFormSubmission, this.get('afterAction'), this.get('beforeAction'), event, this.get('_form'));
      }
    },


    /**
     * If the ENTER or RETURN key is pressed cancel the action via `event.preventDefault()`.
     * Stopping bubbling at this level will prevent the DEFAULT submission of a form as per the HTML spec.
     * This matters, because we want to use `enterSubmitsForm?` to potentially stop the enter key from submitting.
     * @param event the keyboard event.
     */
    keyPress: function keyPress(event) {
      this._super.apply(this, arguments);

      if (event.ctrlKey && (event.keyCode === KeyEvent.DOM_VK_ENTER || event.keyCode === KeyEvent.DOM_VK_RETURN)) {
        event.preventDefault();
      }
    }
  });
});