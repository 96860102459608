define("js-admin-common/components/notification/notification-list", ["exports", "jquery", "js-admin-common/templates/components/notification/notification-list"], function (_exports, _jquery, _notificationList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationList.default,
    tagName: '',
    notificationsService: Ember.inject.service('notifications'),

    /**
     * Notifications to show.
     *
     * @type {Ember.Array}
     */
    notifications: Ember.computed.alias('notificationsService.notifications'),

    /**
     * Number of unread notifications on the server
     * @type Number
     */
    unreadNotificationsCount: Ember.computed.alias('notificationsService.unreadNotificationsCount'),

    /**
     * Boolean to check if the notification service is loading data
     * @type boolean
     */
    isReady: Ember.computed.alias('notificationsService.isReady'),

    /**
     * The current selected notification. This will be displayed in the right panel
     */
    currentNotification: null,
    // /**
    //  * Text to show if there are no notifications.
    //  *
    //  * @type {String}
    //  */
    // noNotificationsText: 'You have no notifications.',
    // notificationOrdering
    ordering: ['createdOn:desc'],

    /**
     * Notifications ordered by date.
     *
     * @type {Ember.Array}
     */
    orderedNotifications: Ember.computed.sort('notifications', 'ordering'),
    newNotifications: Ember.computed.filterBy('orderedNotifications', 'read', 0),

    /**
     * used to know if the app is JS2-admin
     */
    isJSapp: false,
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    tm2AdminURL: Ember.computed('ENV', function () {
      var app = this.get('ENV').APP;

      if ('tm2AdminURL' in app) {
        return app.tm2AdminURL;
      }

      return '';
    }),
    height: Ember.computed('orderedNotifications.length', function () {
      var notifications = this.get('orderedNotifications');
      var notificationNr = notifications.get('length');
      var fullHeight = notificationNr * 112;
      fullHeight = fullHeight > 0 ? fullHeight : 112;
      var wantedHeight = fullHeight > 504 ? 504 : fullHeight;
      var windowHeight = (0, _jquery.default)(window).height();
      var navbar = (0, _jquery.default)('.navbar-custom-menu').height();
      var maxHeight = windowHeight - navbar;
      return wantedHeight > maxHeight ? maxHeight - 80 : wantedHeight;
    }),
    didInsertElement: function didInsertElement() {
      this.get('notificationsService').startNotificationsUpdate();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('notificationsService').stopNotificationsUpdate();
    },
    triggerResize: function triggerResize() {
      // resize screen to calculate the notification list dialog
      window.dispatchEvent(new Event('resize'));
    },
    actions: {
      sideLoadRelated: function sideLoadRelated() {
        // load related if they are not loaded yet
        if (!this.get('notificationsService.relatedLoaded')) {
          this.get('notificationsService.storeRelated').perform(this.get('notifications'));
        }
      },
      dismiss: function dismiss(notification) {
        notification.set('read', 1);
        notification.save();
      },
      markAsUnread: function markAsUnread(notification) {
        notification.set('read', 0);
        notification.save();
      },
      dismissAll: function dismissAll() {
        // TODO: Implement this with a single api call
        var markAsRead = 0;
        this.get('notifications').filterBy('read', 0).forEach(function (unseen) {
          unseen.set('read', 1);
          unseen.save();
          markAsRead++;
        });
      },
      closeNotification: function closeNotification() {
        this.set('currentNotification', null);
        this.triggerResize();
      },
      deleteNotification: function deleteNotification() {
        this.get('currentNotification').destroyRecord();
        this.set('currentNotification', null);
        this.triggerResize();
      },
      openNotification: function openNotification(notification) {
        this.set('currentNotification', notification);

        if (!notification.get('read')) {
          notification.set('read', 1);
          notification.save();
        }

        this.triggerResize();
        return false;
      }
    }
  });

  _exports.default = _default;
});