define("js-admin-common/mixins/with-full-height-pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    // override to define which subpages need full height
    _fullHeightPages: [],
    contentWrapperClass: Ember.computed('application.currentRouteName', function () {
      return this._fullHeightPages.includes(this.get('application.currentRouteName')) ? 'content-wrapper--fullheight' : null;
    })
  });

  _exports.default = _default;
});