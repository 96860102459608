define("js-admin-common/components/billables/edit/time-entry", ["exports", "js-admin-common/templates/components/billables/edit/time-entry"], function (_exports, _timeEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timeEntry.default,
    showCut: false,
    actions: {
      usePhrase: function usePhrase(phrase) {
        this.set('item.description', phrase.get('phrase'));
      }
    }
  });

  _exports.default = _default;
});