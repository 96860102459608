define("js-admin-common/templates/components/model-saved-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qPkvnk5n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"list-inline\"],[11,\"style\",\"display: inline-block;\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"hasDirtyAttributes\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"cloud-upload\"],[[\"color\",\"title\"],[\"orange\",\"Record needs to be saved to the database.\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"check\"],[[\"color\",\"title\"],[\"green\",\"Record has been saved in the database.\"]]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[29,\"not\",[[25,[\"model\",\"isValid\"]]],null],[25,[\"model\",\"isError\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"ban\"],[[\"color\",\"title\"],[\"red\",\"Record was not saved to the database because something went wrong!\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/model-saved-icon.hbs"
    }
  });

  _exports.default = _default;
});