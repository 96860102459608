define("js-admin-common/templates/components/matter/subjects/subject-type-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nx6sdPDE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[5,\"sc-badge\",[],[[\"@color\",\"@size\"],[[23,\"badgeColor\"],\"sm\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,\"subjectTypeInitial\"],false],[0,\"\\n    \"],[5,\"sc-tooltip\",[],[[\"@placement\",\"@color\"],[\"right\",\"dark\"]],{\"statements\":[[0,\"\\n      \"],[1,[29,\"titleize\",[[25,[\"tooltipText\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/matter/subjects/subject-type-cell.hbs"
    }
  });

  _exports.default = _default;
});