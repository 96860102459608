define("js-admin-common/components/matter/subjects/subject-type-cell", ["exports", "js-admin-common/templates/components/matter/subjects/subject-type-cell"], function (_exports, _subjectTypeCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _subjectTypeCell.default,
    showRelationship: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var subjectType = this.get('row.subjectType').split('_')[0];
      var subjectTypeInitial = subjectType.substring(0, 1).toUpperCase();
      this.set('subjectTypeInitial', subjectTypeInitial);
      this.set('badgeColor', subjectTypeInitial === 'J' ? 'tiger' : 'dark');
      var relationshipText = this.get('showRelationship') && this.get('row.relationship') ? ' - ' + this.get('row.relationship') : '';
      this.set('tooltipText', subjectType + relationshipText);
    }
  });

  _exports.default = _default;
});