define("js-admin-common/templates/components/matter/resource-communications-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3iRSfDtn",
    "block": "{\"symbols\":[\"dateCommunications\",\"date\",\"groupedCommunications\",\"sentBy\",\"communication\",\"@communications\",\"@resource\"],\"statements\":[[4,\"each\",[[29,\"-each-in\",[[29,\"group-by\",[\"createdOnDay\",[29,\"sort-by\",[\"createdOn:asc\",[29,\"filter-by\",[\"resource.id\",[24,7,[\"id\"]],[24,6,[]]],null]],null]],null]],null]],null,{\"statements\":[[4,\"each\",[[29,\"-each-in\",[[29,\"group-by\",[\"sentBy.id\",[24,1,[]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"small\"],[9],[0,\"\\n        Alert sent by\\n        \"],[1,[24,3,[\"firstObject\",\"sentBy\",\"fullName\"]],false],[0,\"\\n        on\\n        \"],[1,[29,\"moment-format\",[[24,2,[]],\"MM/DD/YYYY hh:mm a\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"small\"],[9],[0,\"\\n        Sent to\\n\"],[4,\"each\",[[24,3,[]]],null,{\"statements\":[[0,\"          \"],[1,[24,5,[\"recipient\",\"fullName\"]],false],[0,\"\\n\"],[4,\"unless\",[[29,\"eq\",[[24,5,[]],[24,3,[\"lastObject\"]]],null]],null,{\"statements\":[[0,\"            /\\n\"]],\"parameters\":[]},null]],\"parameters\":[5]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[3,4]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/matter/resource-communications-display.hbs"
    }
  });

  _exports.default = _default;
});