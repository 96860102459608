define("js-admin-common/components/input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CHAR_DOT = 46;
  var CHAR_MINUS = 45;
  var CHAR_BACKSPACE = 8;
  var CHAR_TAB = 9;
  var CHAR_SHIFT = 16;
  var CHAR_CTRL = 17;
  var CHAR_DELETE = 46;
  var CHAR_ARROW_LEFT = 37;
  var CHAR_ARROW_RIGHT = 39;
  var allowedKeys = [CHAR_BACKSPACE, CHAR_TAB, CHAR_SHIFT, CHAR_CTRL, CHAR_DELETE, CHAR_ARROW_LEFT, CHAR_ARROW_RIGHT];

  var _default = Ember.TextField.extend({
    numberValue: null,
    disabled: false,
    placeholder: null,
    allowNegative: false,
    precision: 2,
    keyPress: function keyPress(key) {
      var selectionStart = key.target.selectionStart;
      var selectionEnd = key.target.selectionEnd;
      var numberValue = this.value;

      if (allowedKeys.indexOf(key.charCode) !== -1 || allowedKeys.indexOf(key.keyCode) !== -1) {
        return true;
      } // allow - only once at beginning


      if (this.allowNegative && key.charCode === CHAR_MINUS && (!numberValue || numberValue && numberValue.toString().indexOf('-') === -1 && selectionStart === 0 || numberValue && numberValue.toString().indexOf('-') !== -1 && selectionStart === 0 && selectionStart <= selectionEnd)) {
        return true;
      } // allow . anywhere only once


      if (key.charCode === CHAR_DOT && (!numberValue || numberValue && numberValue.toString().indexOf('.') === -1 || numberValue && selectionStart <= numberValue.toString().indexOf('.') && numberValue.toString().indexOf('.') < selectionEnd)) {
        return true;
      } // allow only numbers and respect precision limit


      if (key.charCode >= 48 && key.charCode <= 57 && this.numberPrecisionIsStillValid(selectionStart, selectionEnd)) {
        // 0 - 9
        return true;
      }

      return false;
    },
    change: function change() {
      var newVal = this.value.toString();
      var decimalParts = newVal.split('.');

      if (this.allowNegative && newVal.indexOf('-') === 0) {
        newVal = '-';
      } else {
        newVal = '';
      }

      newVal += decimalParts[0].replace(/\D/g, '');

      if (this.precision > 0 && decimalParts.length > 1) {
        newVal += '.' + decimalParts[1].substring(0, this.precision);
      }

      this.set('value', newVal);
      this.sendAction('changeValue', newVal);
    },
    numberPrecisionIsStillValid: function numberPrecisionIsStillValid(selectionStart, selectionEnd) {
      if (!this.value) {
        return true; // nothing was input until now
      }

      var precision = this.precision;
      var value = this.value.toString();

      if (value.indexOf('.') !== -1) {
        var decimals = value.split('.')[1];

        if (decimals.length < precision) {
          return true;
        } // if .XX decimal limit is reached, user can still type in integer part of number
        // if .XX decimal limit is reached, user can still type by selecting range of numbers and overwriting


        return selectionStart <= value.indexOf('.') || selectionStart < selectionEnd;
      }

      return true;
    }
  });

  _exports.default = _default;
});