define("js-admin-common/mixins/sorted-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Special object to hold information about the fields aligned one to another
   */
  var _default = Ember.Object.extend({
    // What column is this field on the source file
    orderInFile: 0,
    // Order designated by the user through the UI
    order: 0,
    // Internal field name used to map the field on file with the field on system
    fieldName: '',
    // Used only by the system field that has a readable name
    fieldDisplay: '',
    // for file fields, show what system field is related to
    fieldNameAligned: '',
    // show a mark for required fields
    isRequired: false
  });

  _exports.default = _default;
});