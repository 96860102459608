define('ember-cli-text-support-mixins/mixins/closest-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Grab the nearest form.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
     * @private
     */
    _form: Ember.computed(function () {
      return this.element.closest('form');
    })
  });
});