define("js-admin-common/templates/components/recent-matters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZUsw7DM",
    "block": "{\"symbols\":[\"result\",\"search\"],\"statements\":[[5,\"sc-power-select\",[],[[\"@options\",\"@search\",\"@oninput\",\"@onclose\",\"@searchEnabled\",\"@selected\",\"@allowClear\",\"@placeholder\",\"@onchange\",\"@renderInPlace\",\"@size\"],[[24,0,[\"groupedMatters\"]],[29,\"action\",[[24,0,[]],\"searchMatter\"],null],[29,\"action\",[[24,0,[]],\"checkLength\"],null],[29,\"action\",[[24,0,[]],\"resetOptions\"],null],true,[24,0,[\"selectedMatter\"]],true,\"Recently viewed matters & search\",[29,\"action\",[[24,0,[]],\"selectionsChanged\"],null],true,\"sm\"]],{\"statements\":[[0,\"\\n  \"],[7,\"span\"],[12,\"title\",[29,\"concat\",[[24,1,[\"caseNickname\"]],\" - \",[24,1,[\"tmCode\"]]],null]],[9],[0,\"\\n    \"],[1,[29,\"highlight-result\",[[29,\"concat\",[[24,1,[\"truncatedNickname\"]],\" - \",[24,1,[\"tmCode\"]]],null]],[[\"query\"],[[24,2,[]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/recent-matters.hbs"
    }
  });

  _exports.default = _default;
});