define("js-admin-common/mixins/subjects-import-controller", ["exports", "jquery", "npm:papaparse", "string-similarity", "js-admin-common/mixins/sorted-field", "js-admin-common/mixins/subject-import"], function (_exports, _jquery, _npmPapaparse, _stringSimilarity, _sortedField, _subjectImport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var compareTwoStrings = _stringSimilarity.default.compareTwoStrings;
  var parse = _npmPapaparse.default.parse;
  var debug = Ember.Logger.debug;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    breadCrumb: 'CSV Import',
    // start at the first
    currentStep: 1,
    showStep1: Ember.computed.equal('currentStep', 1),
    showStep2: Ember.computed.equal('currentStep', 2),
    showStep3: Ember.computed.equal('currentStep', 3),
    showStep4: Ember.computed.equal('currentStep', 4),
    showFailureMessage: Ember.computed('currentStep', 'partialSubjects.[]', function () {
      var show = false;

      if (this.currentStep === 4) {
        this.partialSubjects.forEach(function (ps) {
          if (ps.get('result') === 'Failure') {
            show = true;
          }
        });
      }

      return show;
    }),
    // Holds the FileReader object on init
    reader: null,
    // If FileReader is not supported shows a message to the user
    notSupported: false,
    // Contents of the file without the header
    data: [],
    // Fields on the file
    fields: [],
    // Fields for the matter
    matterFields: [],
    // Number of lines on the file
    numberOfSubjects: 0,
    // property used for computed.sort
    fieldsSortProperties: ['order:asc'],
    // fields sorted by order
    sortedFields: Ember.computed.sort('fields', 'fieldsSortProperties'),
    // matter fields sorted by order
    sortedMatterFields: Ember.computed.sort('matterFields', 'fieldsSortProperties'),
    processedSubjects: [],
    // starts the spinner button
    isImportingSubjects: false,
    updateFields: 'all',
    matchIdentificationOptions: [{
      label: 'Id',
      value: 'id'
    }, {
      label: 'Last Name + First Name',
      value: 'name'
    }, {
      label: 'Panel Number + Order Number',
      value: 'numbers'
    }],
    selectedMatchIdentification: {
      label: 'Id',
      value: 'id'
    },
    // init function to init the FileReader if available
    checkFileAPI: Ember.on('init', function () {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        this.reader = new FileReader();
      } else {
        this.notify.warning('The File APIs are not fully supported by your browser. Fallback required.');
        this.set('notSupported', true);
      }
    }),
    determineSubjectMatchParams: function determineSubjectMatchParams(criteria, subject) {
      switch (criteria) {
        case 'id':
          return this.determineSubjectMatchParamsById(subject);
          break;

        case 'name':
          return this.determineSubjectMatchParamsByName(subject);
          break;

        case 'numbers':
          return this.determineSubjectMatchParamsByNumbers(subject);
          break;
      }
    },

    /**
     * We need matterFields (system) and fields (imported file) to match as closely as possible
     * We match each pair of fields and save their comparison values in a matrix
     * We find the largest values, one at a time = that is considered a close match and 'remove' its row and column from the matrix
     * After we find all matches, we re-order the fields so the matches will be displayed side by side
     */
    getFieldMatches: function getFieldMatches() {
      var matches = [];
      var fileFields = this.fields;
      var systemFields = this.matterFields;
      var fileFieldsLength = fileFields.get('length');
      var systemFieldsLength = systemFields.get('length');
      /* fields = rows (left)
         matterFields = columns (top) */

      var matrix = [];

      for (var i = 0; i < fileFieldsLength; i++) {
        matrix[i] = [];

        for (var j = 0; j < systemFieldsLength; j++) {
          matrix[i][j] = compareTwoStrings(fileFields[i].get('fieldDisplay'), systemFields[j].get('fieldDisplay'));
        }
      }

      var smallestListLength = fileFieldsLength <= systemFieldsLength ? fileFieldsLength : systemFieldsLength;

      for (var k = 0; k < smallestListLength; k++) {
        var maxValue = 0;
        var maxValueI = 0;
        var maxValueJ = 0; // find max value from matrix

        for (var _i = 0; _i < fileFieldsLength; _i++) {
          for (var _j = 0; _j < systemFieldsLength; _j++) {
            if (matrix[_i][_j] !== '-' && maxValue < matrix[_i][_j]) {
              maxValue = matrix[_i][_j];
              maxValueI = _i;
              maxValueJ = _j;
            }
          }
        } // we have max value coordinates => we have a match


        matches.push([fileFields[maxValueI], systemFields[maxValueJ]]); // we can 'remove' (set value of "-") the matched row and col

        for (var _i2 = 0; _i2 < fileFieldsLength; _i2++) {
          if (_i2 === maxValueI) {
            for (var _j2 = 0; _j2 < systemFieldsLength; _j2++) {
              matrix[_i2][_j2] = '-';
            }
          } else {
            for (var _j3 = 0; _j3 < systemFieldsLength; _j3++) {
              if (_j3 === maxValueJ) {
                matrix[_i2][_j3] = '-';
              }
            }
          }
        }
      }

      return matches;
    },
    orderFields: function orderFields(matches) {
      var fileFields = this.fields;
      var systemFields = this.matterFields;
      var fileFieldsLength = fileFields.get('length');
      var systemFieldsLength = systemFields.get('length'); // set all orders to 0

      fileFields.forEach(function (field) {
        field.set('order', 0);
      });
      systemFields.forEach(function (field) {
        field.set('order', 0);
      }); // set orders. start with found matches then continue with what remains (fields without match that remain in largest of the 2 lists)

      var order = 1;
      matches.forEach(function (match) {
        match[0].set('order', order);
        match[1].set('order', order);
        order += 1;
      });

      if (fileFieldsLength < systemFieldsLength) {
        systemFields.forEach(function (field) {
          if (!field.get('order')) {
            field.set('order', order);
            order += 1;
          }
        });
      } else {
        fileFields.forEach(function (field) {
          if (!field.get('order')) {
            field.set('order', order);
            order += 1;
          }
        });
      }
    },

    /**
     * Translate Subject type to the proper mapping. eg: juror -> juror_subjects
     */
    translateSubjectType: function translateSubjectType(subject) {
      var subjectType = subject.get('subjectType');

      if (subjectType) {
        var newSubjectType = null;
        subjectType = subjectType.toLowerCase().trim();

        switch (subjectType) {
          case 'juror':
            newSubjectType = 'juror_subjects';
            break;

          case 'witness':
            newSubjectType = 'witness_subjects';
            break;

          case 'plaintiff':
            newSubjectType = 'plaintiff_subjects';
            break;

          case 'other':
            newSubjectType = 'other_subjects';
            break;

          case 'decedent':
            newSubjectType = 'decedent_subjects';
            break;

          default:
            newSubjectType = subjectType;
        }

        subject.set('subjectType', newSubjectType);
      }
    },
    fixLocation: function fixLocation(subject) {
      if (subject.get('location')) {
        var subjectLocation = subject.get('location').toLowerCase().trim();
        var possibleLocations = ['pool', 'hardship', 'cause', 'panel', 'defendant', 'plaintiff', 'box', 'alt', 'other', 'active', 'inactive'];
        var index = possibleLocations.indexOf(subjectLocation.toLowerCase().trim());

        if (index < 0) {
          subject.set('location', null);
        } else {
          switch (subjectLocation) {
            case 'active':
              subject.set('location', 'Active');
              break;

            case 'inactive':
              subject.set('location', 'Inactive');
              break;

            default:
              subject.set('location', subjectLocation);
          }
        }
      }
    },

    /**
     * Reads the file using the FileReader object
     */
    readFile: function readFile() {
      var _this = this;

      var filePath = (0, _jquery.default)('#csv-file').get(0);

      if (filePath.files.length === 0) {
        this.notify.error('Please select a csv file first');
        this.set('currentStep', 1);
        return;
      }

      var extension = filePath.files[0].name.toLowerCase().substr(-3);

      if (extension !== 'csv') {
        this.notify.error('Please select a CSV file first');
        this.set('currentStep', 1);
        return;
      }

      parse(filePath.files[0], {
        complete: function complete(results, file) {
          debug('Parsing complete', results, file);

          _this.set('numberOfSubjects', results.data.length - 1);

          var order = 1;
          var orderInFile = 0;

          _this.set('fields', results.data[0].map(function (field) {
            return _sortedField.default.create({
              fieldName: field,
              fieldDisplay: field,
              order: order++,
              orderInFile: orderInFile++
            });
          }));

          _this.set('data', results.data.slice(1));

          var matches = _this.getFieldMatches();

          _this.orderFields(matches);
        }
      });
      this.set('currentStep', 2);
    },

    /**
     * process all subjects and determine whether to add or update existing
     */
    previewSubjects: function previewSubjects() {
      var _this2 = this;

      // line up final imported field with counter part in api
      var sortedFields = this.sortedFields;
      sortedFields.forEach(function (field) {
        var fieldAligned = _this2.sortedMatterFields.findBy('order', field.get('order'));

        if (fieldAligned) {
          field.set('fieldNameAligned', fieldAligned.get('fieldName'));
        }
      });
      var index = 1; // hold a list of subjects and their data including what to process

      var partialSubjects = [];
      var requestMatchesParams = [];
      var criteria = this.get('selectedMatchIdentification.value');
      this.data.forEach(function (line) {
        var currentSubject = _subjectImport.default.create({
          mode: 'processing',
          id: null,
          pseudoId: index
        });

        if (Ember.isEmpty(line) || line.length === 1 && Ember.isEmpty(line[0])) {// do nothing with an empty line
        } else {
          var fields = line;
          var _data = {};
          sortedFields.forEach(function (field) {
            var fieldName = field.get('fieldNameAligned').camelize();

            if (fieldName === 'id') {
              // match proposed, do something with the id
              currentSubject.set('id', fields[field.get('orderInFile')].trim());
            } else {
              var value = fields[field.get('orderInFile')];
              _data[fieldName] = Ember.isPresent(value) ? value.trim() : null;
            }
          }); // store full data set for the new/existing subject

          currentSubject.set('data', _data);
          partialSubjects.pushObject(currentSubject);

          var params = _this2.determineSubjectMatchParams(criteria, currentSubject);

          if (params) {
            requestMatchesParams.push(params);
          }

          index++;
        }
      });
      this.set('partialSubjects', partialSubjects);
      var matter = this.get('model.matter');
      var data = {
        criteria: criteria,
        params: JSON.stringify(requestMatchesParams)
      };
      matter.getSubjectMatches(data).then(function (response) {
        _this2.store.pushPayload({
          subjects: response.subjects
        });

        var allResults = response.match_results;
        allResults.forEach(function (result) {
          var pseudoId = parseInt(result.id);
          var subjectImport = partialSubjects.findBy('pseudoId', pseudoId);
          var matches = result.matches;

          if (matches.length) {
            var matchingSubjects = [];
            matches.forEach(function (matchId) {
              matchingSubjects.push(_this2.store.peekRecord('subject', matchId));
            });
            subjectImport.set('matches', matchingSubjects);
            subjectImport.set('selectedMatch', matchingSubjects[0]);
            subjectImport.set('mode', 'update existing');
          } else {
            _this2.setSubjectAsInsert(subjectImport);
          }
        });
      });
    },
    determineSubjectMatchParamsById: function determineSubjectMatchParamsById(currentSubject) {
      if (!Ember.isEmpty(currentSubject.get('id'))) {
        return {
          pseudo_id: currentSubject.pseudoId,
          resource_id: currentSubject.id
        };
      }

      this.setSubjectAsInsert(currentSubject);
      return null;
    },
    determineSubjectMatchParamsByName: function determineSubjectMatchParamsByName(currentSubject) {
      if (!Ember.isEmpty(currentSubject.get('data.firstName')) && !Ember.isEmpty(currentSubject.get('data.lastName'))) {
        return {
          pseudo_id: currentSubject.pseudoId,
          first_name: currentSubject.get('data.firstName'),
          last_name: currentSubject.get('data.lastName')
        };
      }

      this.setSubjectAsInsert(currentSubject);
      return null;
    },
    determineSubjectMatchParamsByNumbers: function determineSubjectMatchParamsByNumbers(currentSubject) {
      if (!Ember.isEmpty(currentSubject.get('data.courtPanel')) && !Ember.isEmpty(currentSubject.get('data.memberId'))) {
        return {
          pseudo_id: currentSubject.pseudoId,
          panel: currentSubject.get('data.courtPanel'),
          order: currentSubject.get('data.memberId')
        };
      }

      this.setSubjectAsInsert(currentSubject);
      return null;
    },
    setSubjectAsInsert: function setSubjectAsInsert(subject) {
      subject.set('id', undefined);
      subject.set('mode', 'insert');
    },
    actions: {
      /**
       * increment the step and run any required logic
       */
      incStep: function incStep(formerStep) {
        var currentStep = formerStep + 1;

        if (currentStep === 2) {
          this.readFile();
          return;
        }

        if (currentStep === 3) {
          this.previewSubjects();
        }

        if (currentStep === 4) {
          this.importSubjects();
        } // basically start over


        if (currentStep === 5) {
          this.set('currentStep', 1);
          this.set('numberOfSubjects', null);
          this.set('processedSubjects', []);
          this.set('fields', []);
          this.set('data', []);
          return;
        }

        this.set('currentStep', currentStep);
      },

      /**
       * decrement the step and run any required logic
       */
      decStep: function decStep(formerStep) {
        this.set('currentStep', formerStep - 1);
      },

      /**
       * With the contents read from the file
       * create the subjects and send to the api
       */
      importSubjects: function importSubjects() {
        var _this3 = this;

        this.set('isImportingSubjects', true);
        var promises = [];
        var partialSubjects = this.partialSubjects; // either POST or PUT records depending on the mode

        if (this.updateFields === 'all') {
          partialSubjects.forEach(function (subject) {
            var modSubject = null;

            if (subject.get('mode') === 'insert') {
              modSubject = _this3.store.createRecord('subject', subject.get('data'));
              modSubject.set('matter', _this3.get('model.matter'));
              modSubject.set('saveOrderPanel', true);

              _this3.translateSubjectType(modSubject);

              _this3.fixLocation(modSubject);

              promises.pushObject(modSubject.save().then(function () {
                subject.set('result', 'Success');
              }).catch(function () {
                subject.set('result', 'Failure');

                _this3.loadError(modSubject);

                subject.set('errors', _this3.validationList);
              })); // promises.pushObject(newSubject.save());
            } else {
              // update!
              modSubject = _this3.store.peekRecord('subject', subject.get('selectedMatch.id'));
              var data = subject.get('data');
              Object.keys(subject.get('data')).forEach(function (key) {
                modSubject.set(key, data[key]);
              });

              _this3.translateSubjectType(modSubject);

              _this3.fixLocation(modSubject);

              promises.pushObject(modSubject.patch({
                subject: data
              }).then(function () {
                subject.set('result', 'Success');
              }).catch(function (reason) {
                subject.set('result', 'Failure');

                _this3.loadError(modSubject);

                subject.set('errors', _this3.validationList);
              }));
            }
          });
        } else {
          // Only update order/panel
          var firstSubject = null;
          var changes = [];
          partialSubjects.forEach(function (subject) {
            if (Ember.isEmpty(subject.get('selectedMatch.id')) || subject.get('mode') === 'insert') {
              _this3.notify.warning('The option to update order/panel does not support' + ' creating new subjects, please insert them first then renumber with this option', {
                closeAfter: null
              });

              return;
            }

            var modSubject = _this3.store.peekRecord('subject', subject.get('selectedMatch.id'));

            if (!firstSubject) {
              firstSubject = modSubject;
            }

            var data = subject.get('data');
            changes.pushObject({
              id: modSubject.get('id'),
              panel: data.courtPanel,
              order: data.memberId
            });
          });

          if (!firstSubject) {
            this.notify.warning('No subjects found');
          } else {
            promises.pushObject(firstSubject.renumberSubjects({
              list_changes: true,
              subjects: changes
            }).then(function () {
              partialSubjects.forEach(function (subject) {
                if (Ember.isEmpty(subject.get('selectedMatch.id')) || subject.get('mode') === 'insert') {
                  subject.set('result', 'Failure');
                } else {
                  subject.set('result', 'Success');
                }
              });
            }).catch(function (reason) {
              partialSubjects.forEach(function (subject) {
                subject.set('result', 'Failure');
              });
            }));
          }
        }

        Ember.RSVP.all(promises).finally(function () {
          // when all are processed, turn off the spinner
          _this3.set('isImportingSubjects', false);

          _this3.set('currentStep', 4);
        });
      },

      /**
       * action sent by the sortable component to re-order the list of fields
       */
      reorderFields: function reorderFields(fields) {
        var order = 1;
        fields.map(function (f) {
          f.set('order', order++);
        });
      }
    }
  });

  _exports.default = _default;
});