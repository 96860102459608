define('ember-cli-text-support-mixins/mixins/escape-key-clears', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    /**
     * Pass in a closure function to fire after the value is cleared.
     * The function will receive two parameters: the first is the DOM event, and the second is
     * `this` component.
     */
    afterClearAction: function afterClearAction() {
      // override accordingly
    },


    /**
     * Pass in a closure function to fire before the value is cleared.
     * The function will receive two parameters: the first is the DOM event, and the second is
     * `this` component.
     */
    beforeClearAction: function beforeClearAction() {
      // override accordingly
    },


    /**
     * By default, pressing the ESC key will clear this' `value` property.  Set this to `false` if you do not
     * want this behaviour.
     */

    'escapeKeyClears?': true,

    /**
     * If you override make sure to `this._super(...arguments)` to preserve this behaviour.
     */
    cancel: function cancel(event) {
      this._super.apply(this, arguments);
      if (this.get('escapeKeyClears?')) {
        // fire the before-clear action
        if (Ember.isPresent(this.get('beforeClearAction'))) {
          this.get('beforeClearAction')(event, this);
        }

        this.element.value = ''; // for Ember-2.4 & Ember-2.8
        Ember.trySet(this, 'value', '');

        // fire the after-clear action
        if (Ember.isPresent(this.get('afterClearAction'))) {
          this.get('afterClearAction')(event, this);
        }
      }
    }
  });
});