define("js-admin-common/templates/components/icon-resource-viewable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJjptE5P",
    "block": "{\"symbols\":[\"@size\",\"@readonly\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-icon\",[[13,\"class\",\"my-1\"],[13,\"title\",[24,0,[\"title\"]]]],[[\"@icon\",\"@iconSet\",\"@fixedWidthIcon\",\"@size\",\"@color\"],[[24,0,[\"iconName\"]],[24,0,[\"iconSetName\"]],true,[24,1,[]],[24,0,[\"color\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"sc-icon-button\",[[13,\"class\",\"my-1\"],[13,\"title\",[24,0,[\"title\"]]]],[[\"@icon\",\"@iconSet\",\"@fixedWidthIcon\",\"@size\",\"@color\",\"@onClick\"],[[24,0,[\"iconName\"]],[24,0,[\"iconSetName\"]],true,[24,1,[]],[24,0,[\"color\"]],[29,\"action\",[[24,0,[]],\"changeViewable\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/icon-resource-viewable.hbs"
    }
  });

  _exports.default = _default;
});