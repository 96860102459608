define("js-admin-common/templates/components/subjects/publish-subjects-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y8v8coia",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"styleForJSI\"]],\"link\",\"btn btn-sm btn-primary list-btn\"],null]]]],[11,\"title\",\"Publish multiple subjects\"],[9],[0,\"\\n  Publish\\n  \"],[1,[29,\"fa-icon\",[\"exchange\"],null],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"togglePublishSubjectsModal\"]],[10],[0,\"\\n\"],[4,\"bs-modal-simple\",null,[[\"title\",\"closeTitle\",\"submitTitle\",\"size\",\"fade\",\"open\",\"onSubmit\",\"onHide\"],[\"Publish Subjects Confirm\",\"Cancel\",\"Yes, Publish\",null,false,[25,[\"showModal\"]],[29,\"action\",[[24,0,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[25,[\"publishSubjects\"]],[25,[\"selectedRows\"]]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showModal\"]]],null],false],null]],null]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showModal\"]]],null],false],null]]],{\"statements\":[[4,\"if\",[[29,\"gt\",[[25,[\"selectedRows\",\"length\"]],1],null]],null,{\"statements\":[[0,\"    Are you sure you want to publish these subjects?\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Are you sure you want to publish this subject?\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/subjects/publish-subjects-button.hbs"
    }
  });

  _exports.default = _default;
});