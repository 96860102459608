define("js-admin-common/utils/alert-users", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEmployeesModel = getEmployeesModel;
  _exports.getContactsModel = getContactsModel;

  /**
   * functions used to retrieve data that is required by the alert-users component
   * ! must receive following params: store & matter
   *
   */
  function getEmployeesModel(store, matter) {
    return Ember.RSVP.hash({
      matterHasUsers: store.query('matter-has-user', {
        matter_id: matter.id,
        user_type: 'employee',
        with: 'users',
        'users:status': 'Active'
      }),
      matterHasTeams: store.query('matter-has-team', {
        matter_id: matter.id,
        'teams:mode': 'Employee',
        with: 'teams'
      })
    }).then(function (response) {
      var caseEmployees = response.matterHasUsers.mapBy('user');
      response.caseTeams = response.matterHasTeams.map(function (mht) {
        mht.get('team').set('employees', []);
        return mht.get('team');
      }).sortBy('name');
      var teamIds = response.matterHasTeams.map(function (mht) {
        return mht.get('team.id');
      });
      return store.query('user-has-team', {
        team_id: teamIds,
        with: 'users,teams',
        'users:status': 'Active'
      }).then(function (uhts) {
        var employees = [];
        uhts.map(function (uht) {
          var employee = uht.get('user');
          employee.set('isSelected', false);
          employees.pushObject(employee);
          var team = response.caseTeams.findBy('id', uht.get('team.id'));

          if (team) {
            team.get('employees').pushObject(employee);
          }
        });
        employees.forEach(function (e) {
          if (Ember.isEmpty(caseEmployees.findBy('id', e.get('id')))) {
            caseEmployees.pushObject(e);
          }
        });
        response.caseEmployees = caseEmployees.sortBy('fullName');
        return response;
      });
    });
  }

  function getContactsModel(store, matter) {
    var autoSelectReport = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return Ember.RSVP.hash({
      matterHasUsers: store.query('matter-has-user', {
        matter_id: matter.id,
        user_type: 'client',
        group_id: '!=' + _emberGetConfig.default.APP.dormantGroupId,
        with: 'users,groups'
      }),
      matterHasTeams: store.query('matter-has-team', {
        matter_id: matter.id,
        'teams:mode': 'Contact',
        group_id: '!=' + _emberGetConfig.default.APP.dormantGroupId,
        with: 'teams,groups'
      })
    }).then(function (response) {
      var caseContacts = response.matterHasUsers.map(function (mhu) {
        var u = mhu.belongsTo('user').value();
        u.set('groupName', mhu.get('group.name'));
        return u;
      });
      response.reportGroup = Ember.Object.create({
        name: 'Report',
        isSelected: autoSelectReport,
        contacts: response.matterHasUsers.filterBy('report').map(function (mhu) {
          var u = mhu.belongsTo('user').value();
          u.set('groupName', mhu.get('group.name'));
          return u;
        })
      });
      response.caseContactTeams = response.matterHasTeams.map(function (mht) {
        mht.get('team').set('contacts', []);
        return mht.get('team');
      }).sortBy('name');
      var teamIds = response.matterHasTeams.map(function (mht) {
        return mht.get('team.id');
      });
      return store.query('user-has-team', {
        team_id: teamIds,
        with: 'users,teams'
      }).then(function (uhts) {
        var contacts = [];
        uhts.map(function (uht) {
          var contact = uht.get('user');
          contact.set('groupName', response.matterHasTeams.findBy('team.id', uht.get('team.id')).get('group.name'));
          contacts.pushObject(contact);
          var team = response.caseContactTeams.findBy('id', uht.get('team.id'));

          if (team) {
            team.get('contacts').pushObject(contact);
          }
        });
        contacts.forEach(function (e) {
          if (Ember.isEmpty(caseContacts.findBy('id', e.get('id')))) {
            caseContacts.pushObject(e);
          }
        });
        response.caseContacts = caseContacts.map(function (c) {
          if (autoSelectReport) {
            c.set('isSelected', response.reportGroup.contacts.findBy('id', c.get('id')));
          } else {
            c.set('isSelected', false);
          }

          c.set('report', response.reportGroup.contacts.findBy('id', c.get('id')));
          return c;
        }).sortBy('fullName');
        return response;
      });
    });
  }
});