define("js-admin-common/mixins/workflows-edit-route", ["exports", "js-admin-common/utils/editing-workflow"], function (_exports, _editingWorkflow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentMatter: Ember.inject.service(),
    lists: Ember.inject.service(),
    model: function model(params) {
      var matterId = this.get('currentMatter.id');
      var subjectId = params.subject_id;
      return Ember.RSVP.hash({
        subjects: this.store.queryRecord('subject', {
          id: subjectId,
          with: 'subject_workflows'
        }),
        editWorkflows: this.store.query('subject-edit-workflow', {
          matter_id: matterId,
          subject_id: subjectId
        }),
        subjectWorkflowColumns: this.store.query('subject-workflow-column', {
          matter_id: matterId,
          include_matter_fields: false,
          label_only: true
        }),
        matterHasWorkflow: this.store.query('matter-has-workflow', {
          matter_id: matterId,
          with: 'workflows'
        }),
        simpleWorkflows: this.store.query('simple-workflow', {
          subject_id: subjectId,
          with: 'employees'
        }),
        matterFields: this.store.query('matter-field', {
          matter_id: matterId
        }),
        employees: this.store.query('employee', {
          with_access_to_matter: matterId
        }),
        workflowStatuses: this.lists.getListForGroup('workflow_status')
      });
    },
    setupController: function setupController(controller, resolved) {
      resolved.editWorkflows.forEach(function (ew) {
        ew.set('statusOption', resolved.workflowStatuses.findBy('value', ew.get('status')));
      });

      this._super(controller, resolved.subjects);

      controller.set('employees', resolved.employees);
      controller.set('editWorkflows', resolved.editWorkflows);
      controller.set('workflowStatuses', resolved.workflowStatuses.sortBy('weight'));
      controller.set('matterFields', resolved.matterFields);
      controller.set('subjectWorkflowColumns', resolved.subjectWorkflowColumns);
      controller.set('simpleWorkflows', resolved.simpleWorkflows.map(function (w) {
        return _editingWorkflow.default.create({
          content: w,
          editing: false,
          statuses: resolved.workflowStatuses
        });
      }));
      controller.set('matterHasWorkflow', resolved.matterHasWorkflow);
    }
  });

  _exports.default = _default;
});