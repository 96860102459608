define("js-admin-common/mixins/workflows-edit-controller", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_error.default, {
    breadCrumb: 'Workflows',
    breadCrumbPath: 'matter.workflows.list',
    currentMatter: Ember.inject.service(),
    // store a list of enabled fields and workflows for this case
    matterFieldColumns: [],
    workflowColumns: [],
    showComments: Ember.computed('editWorkflows.@each.showComment', function () {
      return this.editWorkflows.isAny('showComment', true);
    }),
    actions: {
      /**
       * save the new/existing workflow
       * also update subject if local changes are detected
       */
      save: function save() {
        var _this = this;

        var self = this;
        var subject = this.model; // load custom workflow objects

        var workflows = this.editWorkflows;
        var canSave = true;
        workflows.forEach(function (w) {
          if (w.get('status') === 'C' && w.get('clientName') === 'analyticsPhase1' && !subject.get('isPhaseOneAnalyticsFilled')) {
            _this.notify.warning('Please complete all the information of the analytics phase one');

            canSave = false;
            return;
          }

          subject.set(w.get('clientName') + 'A', w.get('assignedTo'));
          subject.set(w.get('clientName') + 'S', w.get('status'));
          subject.set(w.get('clientName') + 'C', w.get('comment'));
        });

        if (!canSave) {
          return;
        }

        var actions = [];

        if (subject.get('hasDirtyAttributes')) {
          actions[actions.length] = subject.saveWorkflow({
            subject: subject.serializeWithConflictResolution()
          }).then(function () {}, function () {
            self.validationReport(subject);
          });
        } // return message when everything is saved correctly


        Ember.RSVP.hash(actions).then(function (hash) {
          self.get('notify').success('Information Saved');
          self.transitionToRoute('matter.workflows.list');
        });
      },
      changeStatus: function changeStatus(workflow, newStatus) {
        workflow.set('child.statusOption', newStatus);

        if (newStatus) {
          workflow.set('child.status', newStatus.get('value'));
        } else {
          workflow.set('child.status', undefined);
        }
      },
      cancel: function cancel() {
        this.model.rollbackAttributes();
        this.editWorkflows.forEach(function (w) {
          w.rollbackAttributes();
        });
        this.transitionToRoute('matter.workflows.list', this.get('currentMatter.id'));
      }
    }
  });

  _exports.default = _default;
});