define("ember-ckeditor/utils/lineheight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lineheight;

  /** globals: CKEDITOR */
  (function () {
    function addCombo(editor, comboName, styleType, lang, entries, defaultLabel, styleDefinition, order) {
      var config = editor.config,
          style = new CKEDITOR.style(styleDefinition);
      var names = entries.split(';'),
          values = [];
      var styles = {};

      for (var i = 0; i < names.length; i++) {
        var parts = names[i];

        if (parts) {
          parts = parts.split('/');
          var vars = {},
              name = names[i] = parts[0];
          vars[styleType] = values[i] = parts[1] || name;
          styles[name] = new CKEDITOR.style(styleDefinition, vars);
          styles[name]._.definition.name = name;
        } else names.splice(i--, 1);
      }

      editor.ui.addRichCombo(comboName, {
        label: editor.lang.lineheight.title,
        title: editor.lang.lineheight.title,
        toolbar: 'styles,' + order,
        allowedContent: style,
        requiredContent: style,
        panel: {
          css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
          multiSelect: false,
          attributes: {
            'aria-label': editor.lang.lineheight.title
          }
        },
        init: function init() {
          this.startGroup(editor.lang.lineheight.title);

          for (var i = 0; i < names.length; i++) {
            var name = names[i];
            this.add(name, styles[name].buildPreview(), name);
          }
        },
        onClick: function onClick(value) {
          editor.focus();
          editor.fire('saveSnapshot');
          var style = styles[value];
          editor[this.getValue() == value ? 'removeStyle' : 'applyStyle'](style);
          editor.fire('saveSnapshot');
        },
        onRender: function onRender() {
          editor.on('selectionChange', function (ev) {
            var currentValue = this.getValue();
            var elementPath = ev.data.path,
                elements = elementPath.elements;

            for (var i = 0, element; i < elements.length; i++) {
              element = elements[i];

              for (var value in styles) {
                if (styles[value].checkElementMatch(element, true, editor)) {
                  if (value != currentValue) this.setValue(value);
                  return;
                }
              }
            }

            this.setValue('', defaultLabel);
          }, this);
        },
        refresh: function refresh() {
          if (!editor.activeFilter.check(style)) this.setState(CKEDITOR.TRISTATE_DISABLED);
        }
      });
    }

    CKEDITOR.plugins.add('lineheight', {
      requires: 'richcombo',
      lang: 'en,fr,es',
      init: function init(editor) {
        var config = editor.config;
        addCombo(editor, 'lineheight', 'size', editor.lang.lineheight.title, config.line_height, editor.lang.lineheight.title, config.lineHeight_style, 40);
      }
    });
  })();

  CKEDITOR.config.line_height = '1;2;3;4;5;6;7;8;9;10;11;12;13;14;15;16;17;18;19;20;21;22;23;24;25;26;27;28;29;30;31;32;33;34;35;36;37;38;39;40;41;42;43;44;45;46;47;48;49;50;51;52;53;54;55;56;57;58;59;60;61;62;63;64;65;66;67;68;69;70;71;72';
  CKEDITOR.config.lineHeight_style = {
    element: 'span',
    styles: {
      'line-height': '#(size)'
    },
    overrides: [{
      element: 'line-height',
      attributes: {
        'size': null
      }
    }]
  }; // lang/LANG.js

  CKEDITOR.plugins.setLang('lineheight', '{LANGUAGE_CODE}', {
    title: '{LINE-HEIGHT}'
  }); // lang/en.js

  CKEDITOR.plugins.setLang('lineheight', 'en', {
    title: 'Line Height'
  });

  function lineheight() {
    return true;
  }
});