define("ember-ckeditor/components/ember-ckeditor", ["exports", "ember-ckeditor/templates/components/ember-ckeditor", "ember-ckeditor/utils/lineheight"], function (_exports, _emberCkeditor, _lineheight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _emberCkeditor.default,
    _editor: null,
    'on-change': null,
    config: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _lineheight.default)();
      var config = this.get('config') || {};
      var textarea = this.element.querySelector('.editor');
      var editor = this._editor = CKEDITOR.replace(textarea, config);
      editor.on('change', function (e) {
        _this.set('value', e.editor.getData());

        _this.get('on-change')(e.editor.getData());
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._editor.destroy();

      this._editor = null;
    }
  });

  _exports.default = _default;
});