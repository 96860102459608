define("js-admin-common/templates/components/matter/notes/matter-notes-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/rT820v",
    "block": "{\"symbols\":[\"note\",\"@publishNote\",\"@editNote\",\"@reply\",\"@saveNote\",\"@deleteNote\",\"@communications\"],\"statements\":[[4,\"each\",[[29,\"filter-by\",[\"parentId\",[29,\"action\",[[24,0,[]],\"isNull\"],null],[25,[\"notes\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[29,\"matter/notes/note-message\",null,[[\"note\",\"communications\",\"deleteNote\",\"saveNote\",\"reply\",\"editNote\",\"publishNote\"],[[24,1,[]],[24,7,[]],[24,6,[]],[24,5,[]],[24,4,[]],[24,3,[]],[24,2,[]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n  \"],[7,\"hr\"],[11,\"class\",\"mt-4\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center mt-5\"],[9],[0,\"\\n    There are no notes for this case\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/matter/notes/matter-notes-container.hbs"
    }
  });

  _exports.default = _default;
});