define('ember-cli-text-support-mixins/mixins/trigger-focus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    /**
     * @default autofocus is set to false.
     */
    autofocus: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('autofocus')) {
        this.element.focus();
      }
    }
  });
});