define("@ember/render-modifiers/modifiers/did-update", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray"], function (_exports, _classCallCheck2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The `{{did-update}}` element modifier is activated when any of its arguments
    are updated. It does not run on initial render.
  
    In this example, the `resize` function receives the `textarea` DOM element as its
    first argument and is executed anytime the `@text` argument changes.
  
    ```handlebars
    <textarea {{did-update this.resize @text}} readonly style="padding: 0px;">
      {{@text}}
    </textarea>
    ```
  
    ```js
    export default Component.extend({
      resize(element) {
        element.style.height = `${element.scrollHeight}px`;
      }
    });
    ```
  
    In addition to the `element`, both named and positional arguments are passed to the
    executed function:
  
    ```handlebars
    <div {{did-update this.logArguments @first @second third=@third}} />
    ```
  
    ```js
    export default Component.extend({
      logArguments(element, [first, second], { third }) {
        console.log('element', element);
        console.log('positional args', first, second);
        console.log('names args', third);
      }
    });
    ```
  
    By default, the executed function will be unbound. If you would like to access
    the component context in your function, use the `action` decorator as follows:
  
    ```handlebars
    <div {{did-update this.someFunction @someArg} />
    ```
  
    ```js
    export default Component.extend({
      someFunction: action(function(element, [someArg]) {
        // the `this` context will be the component instance
      })
    });
    ```
  
    @method did-update
    @public
  */
  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13'),
      createModifier: function createModifier() {
        return {
          element: null
        };
      },
      installModifier: function installModifier(state, element) {
        // save element into state bucket
        state.element = element;
      },
      updateModifier: function updateModifier(_ref, args) {
        var element = _ref.element;

        var _args$positional = (0, _toArray2.default)(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      destroyModifier: function destroyModifier() {}
    };
  }, function DidUpdateModifier() {
    (0, _classCallCheck2.default)(this, DidUpdateModifier);
  });

  _exports.default = _default;
});