define("js-admin-common/templates/components/billables/edit/to-be-uploaded-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwBdMhyQ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[11,\"id\",\"to-be-uploaded\"],[9],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table table-striped\"],[9],[0,\"\\n      \"],[7,\"thead\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"\\n            To Be Uploaded\\n          \"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"\\n            Size\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"fileList\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"\\n              \"],[1,[24,1,[\"name\"]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"\\n              \"],[1,[24,1,[\"size\"]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/billables/edit/to-be-uploaded-list.hbs"
    }
  });

  _exports.default = _default;
});