define("js-admin-common/components/notification/notification-card", ["exports", "js-admin-common/templates/components/notification/notification-card"], function (_exports, _notificationCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationCard.default,
    store: Ember.inject.service(),
    classNames: ['notification__notification-card'],
    notification: null,
    tasks: null,
    notes: null,
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    tm2AdminURL: Ember.computed('ENV', function () {
      var app = this.get('ENV').APP;

      if ('tm2AdminURL' in app) {
        return app.tm2AdminURL;
      }

      return '';
    }),
    tmUrl: Ember.computed.and('notification.matter.isNotJuryCase', 'isJSapp'),
    task: Ember.computed('tasks.[]', 'notification.{objectId,objectType}', {
      get: function get() {
        if (this.get('notification.objectType') !== 'task') {
          return null;
        }

        return this.get('tasks').filterBy('id', this.get('notification.objectId').toString()).get('firstObject');
      }
    }),
    note: Ember.computed('notes.[]', 'notification.{objectId,objectType}', {
      get: function get() {
        if (this.get('notification.objectType') !== 'note') {
          return null;
        }

        return this.get('notes').filterBy('id', this.get('notification.objectId').toString()).get('firstObject');
      }
    }),
    document: Ember.computed('documents.[]', 'notification.{objectId,objectType}', {
      get: function get() {
        if (this.get('notification.objectType') !== 'document') {
          return null;
        }

        return this.get('documents').filterBy('id', this.get('notification.objectId').toString()).get('firstObject');
      }
    }),
    scrape: Ember.computed('scrapes.[]', 'notification.{objectId,objectType}', {
      get: function get() {
        if (this.get('notification.objectType') !== 'scrape') {
          return null;
        }

        return this.get('scrapes').filterBy('id', this.get('notification.objectId').toString()).get('firstObject');
      }
    }),
    subject: Ember.computed('note', 'notification.notificationType.name', 'notification.{objectId,objectType}', {
      get: function get() {
        if (!this.get('note') || this.get('notification.notificationType').get('name') !== 'SubjectNote') {
          return null;
        }

        var note = this.get('note');
        var vNote = note.hasMany('vNoteParents').value().get('firstObject');

        if (vNote) {
          return vNote.get('subject');
        } else {
          return null;
        }
      }
    })
  });

  _exports.default = _default;
});