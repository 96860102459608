define("js-admin-common/components/notification/notification-item", ["exports", "js-admin-common/templates/components/notification/notification-item"], function (_exports, _notificationItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationItem.default,
    tagName: 'li',
    classNameBindings: ['isActive'],
    classNames: ['list-group-item', 'notification__notification-item'],
    isActive: false,
    notification: null,
    displayLarge: true,
    colorStyle: Ember.computed('notification.notificationType.color', function () {
      var value = this.get('notification.notificationType.color');
      return Ember.String.htmlSafe("color:".concat(value));
    }),
    actions: {
      dismiss: function dismiss() {
        this.get('notification').set('read', 1);
        this.get('notification').save();
      }
    }
  });

  _exports.default = _default;
});