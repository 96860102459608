define("js-admin-common/components/model-saved-icon", ["exports", "js-admin-common/templates/components/model-saved-icon"], function (_exports, _modelSavedIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modelSavedIcon.default,
    tagName: '',

    /**
     * @property
     * @public
     *
     * model to show on the UI if was saved or not
     */
    model: null
  });

  _exports.default = _default;
});