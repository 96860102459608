define("js-admin-common/components/matter/workflows/edit-page", ["exports", "js-admin-common/templates/components/matter/workflows/edit-page"], function (_exports, _editPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _editPage.default
  });

  _exports.default = _default;
});