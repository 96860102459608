define('ember-cli-bootstrap3-popover/components/twbs-popover/trigger', ['exports', 'ember-cli-bootstrap3-popover/templates/components/twbs-popover/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['twbs-popover-trigger'],
    layout: _trigger.default,
    tagName: 'span',
    _register: Ember.on('didInsertElement', function () {
      if (Ember.isPresent(this.get('register'))) {
        this.get('register')(this.$());
      } else {
        Ember.Logger.warn('The `twbs-popover.trigger` component should have an action assigned to its `register` property.');
      }
    })
  });
});