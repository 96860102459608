define("ember-cli-bootstrap3-popover/templates/components/twbs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lGardQnk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"trigger\",\"content\",\"title\"],[[29,\"component\",[\"twbs-popover/trigger\"],[[\"register\"],[[29,\"action\",[[24,0,[]],\"setTriggerElement\"],null]]]],[29,\"component\",[\"twbs-popover/content\"],[[\"register\"],[[29,\"action\",[[24,0,[]],\"setPopoverContent\"],null]]]],[29,\"component\",[\"twbs-popover/title\"],[[\"register\"],[[29,\"action\",[[24,0,[]],\"setPopoverTitle\"],null]]]]]]],[29,\"action\",[[24,0,[]],\"hide\"],null],[29,\"action\",[[24,0,[]],\"show\"],null],[29,\"action\",[[24,0,[]],\"toggle\"],null]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-bootstrap3-popover/templates/components/twbs-popover.hbs"
    }
  });

  _exports.default = _default;
});