define("js-admin-common/templates/components/billables/edit/time-in-piece-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PR/Ac12h",
    "block": "{\"symbols\":[\"result\",\"search\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group col-md-10\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        Type #\\n      \"],[10],[0,\"\\n      \"],[5,\"sc-power-select\",[],[[\"@options\",\"@searchField\",\"@searchEnabled\",\"@selected\",\"@placeholder\",\"@onchange\"],[[23,\"timeList\"],\"name\",true,[25,[\"item\",\"billableType\"]],\"Please select a Billable Type\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"item\",\"billableType\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[29,\"highlight-result\",[[24,1,[\"name\"]]],[[\"query\"],[[24,2,[]]]]],false],[0,\"\\n      \"]],\"parameters\":[1,2]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group col-md-10\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group col-md-10\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        Rate\\n      \"],[10],[0,\"\\n      \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control col-sm-8\",[25,[\"item\",\"rate\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group col-md-10\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        Cut\\n      \"],[10],[0,\"\\n      \"],[1,[29,\"input-number\",null,[[\"value\",\"changeValue\",\"precision\",\"class\"],[[29,\"readonly\",[[25,[\"item\",\"cut\"]]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"item\",\"cut\"]]],null]],null],2,\"no-spinners form-control\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/billables/edit/time-in-piece-entry.hbs"
    }
  });

  _exports.default = _default;
});