define("ember-steps/-private/step-node", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _classCallCheck2, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StepNode = (0, _decorate2.default)(null, function (_initialize) {
    var StepNode = function StepNode(sm, name, context, onActivate, onDeactivate) {
      (0, _classCallCheck2.default)(this, StepNode);
      this.sm = sm;

      _initialize(this);

      this.name = name;
      this.context = context;
      this.onActivate = onActivate;
      this.onDeactivate = onDeactivate;
    };

    return {
      F: StepNode,
      d: [{
        kind: "field",
        key: "name",
        value: void 0
      }, {
        kind: "field",
        key: "context",
        value: void 0
      }, {
        kind: "field",
        key: "onActivate",
        value: void 0
      }, {
        kind: "field",
        key: "onDeactivate",
        value: void 0
      }, {
        kind: "get",
        key: "hasNext",
        value: function hasNext() {
          return Ember.isPresent(this.sm.pickNext(this.name));
        }
      }, {
        kind: "get",
        key: "hasPrevious",
        value: function hasPrevious() {
          return Ember.isPresent(this.sm.pickPrevious(this.name));
        }
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('sm.currentStep')],
        key: "isActive",
        value: function isActive() {
          return Ember.get(this.sm, 'currentStep') === this.name;
        }
      }]
    };
  });
  _exports.default = StepNode;
});