define("js-admin-common/components/matter/workflows/assign-multiple", ["exports", "js-admin-common/templates/components/matter/workflows/assign-multiple"], function (_exports, _assignMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _assignMultiple.default,
    showCommentColumn: Ember.computed('activeWorkflows.[]', function () {
      var show = false;
      var activeWorkflows = this.get('activeWorkflows');

      if (activeWorkflows) {
        activeWorkflows.forEach(function (aw) {
          if (aw.get('comment') !== false) {
            show = true;
          }
        });
      }

      return show;
    }),
    showHoursColumn: Ember.computed('activeWorkflows.[]', function () {
      var show = false;
      var activeWorkflows = this.get('activeWorkflows');

      if (activeWorkflows) {
        activeWorkflows.forEach(function (aw) {
          if (aw.get('hours') !== false) {
            show = true;
          }
        });
      }

      return show;
    }),
    showDeadlineColumn: Ember.computed('activeWorkflows.[]', function () {
      var show = false;
      var activeWorkflows = this.get('activeWorkflows');

      if (activeWorkflows) {
        activeWorkflows.forEach(function (aw) {
          if (aw.get('deadline') !== false) {
            show = true;
          }
        });
      }

      return show;
    }),
    selectedJuryLocation: null,
    initComponent: Ember.on('init', function () {
      this.set('selectedJuryLocation', Ember.Object.create({
        id: 'no_change',
        value: 'no_change',
        display: 'No Change'
      }));
    })
  });

  _exports.default = _default;
});