define("js-admin-common/components/billables/edit/receipt-list", ["exports", "js-admin-common/templates/components/billables/edit/receipt-list"], function (_exports, _receiptList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _receiptList.default,
    availableOptions: Ember.computed('receiptList.[]', 'receiptList.@each.billable', 'expenses.[]', 'expenses.@each.id', function () {
      var receipts = this.get('receiptList');
      var expensesTiedToReceiptIds = receipts.mapBy('billable.id');
      return this.get('expenses').filter(function (expense) {
        return !expense.get('isNew') && expensesTiedToReceiptIds.indexOf(expense.get('id')) === -1;
      });
    }),
    actions: {
      removeReceipt: function removeReceipt(receipt) {
        this.sendAction('removeReceipt', receipt);
      },
      saveReceipt: function saveReceipt(receipt) {
        this.sendAction('saveReceipt', receipt);
      },
      changeBillable: function changeBillable(receipt, selectedBillable) {
        // Had to do this instead of mut receipt.billable b/c it was not working with pipe helper
        receipt.set('billable', selectedBillable);
      }
    }
  });

  _exports.default = _default;
});