define("js-admin-common/utils/editing-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extend a Simple Workflow to have some extra fields/computed
   * in the front end
   */
  var _default = Ember.ObjectProxy.extend({
    // using .content b/c assignedTo is an async relation
    hasAssignedTo: Ember.computed('fullName', function () {
      var assignedTo = this.fullName;
      return Ember.isPresent(assignedTo);
    }),
    hasStatus: Ember.computed.notEmpty('status'),
    hasComment: Ember.computed.notEmpty('comment'),
    observesAssign: Ember.observer('assignedTo', function () {
      if (Ember.isPresent(this.assignedTo)) {
        this.set('firstName', this.get('assignedTo.firstName'));
        this.set('lastName', this.get('assignedTo.lastName'));
      }
    }),
    statuses: [],
    displayForStatus: Ember.computed('status', 'statuses.[]', function () {
      var status = this.status;
      var statuses = this.statuses;
      var st = statuses.findBy('value', status);
      return st.get('display');
    }),
    labelForStatus: Ember.computed('status', function () {
      switch (this.status) {
        case 'P':
          return 'label-warning';

        case 'I':
          return 'label-danger';

        case 'C':
          return 'label-success';

        case 'S':
          return 'label-primary';

        case 'U':
          return 'label-default';

        default:
          return 'label-default';
      }
    }),
    statusOption: Ember.computed('status', 'statuses.[]', function () {
      var status = this.status;
      var statuses = this.statuses;
      var st = statuses.findBy('value', status);
      return st;
    })
  });

  _exports.default = _default;
});