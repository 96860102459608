define("js-admin-common/templates/components/subjects/delete-subjects-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UzOLBxPG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-sm btn-primary list-btn\"],[11,\"title\",\"Delete multiple subjects\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleDeleteSubjectsModal\"],null]],[9],[0,\"\\n  Delete\\n\"],[10],[0,\"\\n\"],[4,\"bs-modal-simple\",null,[[\"backdropClose\",\"title\",\"closeTitle\",\"submitTitle\",\"size\",\"fade\",\"open\",\"onSubmit\",\"onHide\"],[false,\"Delete Subjects Confirm\",\"Cancel\",\"Yes, Delete\",null,false,[25,[\"showDeletModal\"]],[29,\"action\",[[24,0,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[25,[\"deleteSubjects\"]],[25,[\"selectedRows\"]]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showDeletModal\"]]],null],false],null]],null]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"showDeletModal\"]]],null],false],null]]],{\"statements\":[[4,\"if\",[[29,\"gt\",[[25,[\"selectedRows\",\"length\"]],1],null]],null,{\"statements\":[[0,\"    Are you sure you want to delete these subjects?\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Are you sure you want to delete this subject?\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/subjects/delete-subjects-button.hbs"
    }
  });

  _exports.default = _default;
});