define("ember-href-to/helpers/href-to", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hrefTo = hrefTo;
  _exports.default = void 0;

  function hrefTo(context, params) {
    var routing = Ember.getOwner(context).lookup("service:-routing");
    return routing.generateURL.apply(routing, (0, _toConsumableArray2.default)(getParamsForGenerateURL(params)));
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy

    var targetRouteName = params.shift(); // the first param is always the target route name

    var lastParam = params[params.length - 1]; // the last param might be queryParams

    var queryParams;

    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }

    var models = params; // the remainder are the models

    return [targetRouteName, models, queryParams];
  }

  var _default = Ember.Helper.extend({
    compute: function compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this, namedArgs.params);
      } else {
        return hrefTo(this, params);
      }
    }
  });

  _exports.default = _default;
});