define("ember-steps/templates/components/step-manager/step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MuJ9YOk",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[15,2,[[29,\"hash\",null,[[\"hasNext\",\"hasPrevious\"],[[25,[\"hasNext\"]],[25,[\"hasPrevious\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-steps/templates/components/step-manager/step.hbs"
    }
  });

  _exports.default = _default;
});