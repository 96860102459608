define("@html-next/vertical-collection/components/vertical-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SP15OAJv",
    "block": "{\"symbols\":[\"virtualComponent\",\"&inverse\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"virtualComponents\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[1,[29,\"unbound\",[[24,1,[\"upperBound\"]]],null],false],[4,\"if\",[[24,1,[\"isOccludedContent\"]]],null,{\"statements\":[[1,[29,\"unbound\",[[24,1,[\"element\"]]],null],true]],\"parameters\":[]},{\"statements\":[[15,3,[[24,1,[\"content\"]],[24,1,[\"index\"]]]]],\"parameters\":[]}],[1,[29,\"unbound\",[[24,1,[\"lowerBound\"]]],null],false]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"shouldYieldToInverse\"]]],null,{\"statements\":[[0,\"  \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs"
    }
  });

  _exports.default = _default;
});