define("js-admin-common/mixins/document-search-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['t', 'dt', 's', 'd', 'm'],
    t: null,
    dt: null,
    s: null,
    d: null,
    m: null,
    matterChanged: Ember.observer('selectedMatter', function () {
      if (this.selectedMatter) {
        this.set('m', this.get('selectedMatter.id'));
      }
    })
  });

  _exports.default = _default;
});