define("js-admin-common/components/subjects/publish-subjects-button", ["exports", "jquery", "js-admin-common/templates/components/subjects/publish-subjects-button"], function (_exports, _jquery, _publishSubjectsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _publishSubjectsButton.default,
    notify: Ember.inject.service(),
    selectedRows: null,
    styleForJSI: false,
    actions: {
      togglePublishSubjectsModal: function togglePublishSubjectsModal() {
        var selectedRows = this.get('selectedRows');

        if (selectedRows.length > 0) {
          this.set('showModal', true);
        } else {
          this.get('notify').error('You must selected at least one subject.');
        }
      }
    }
  });

  _exports.default = _default;
});