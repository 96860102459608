define("js-admin-common/components/billables/edit/expense-entry", ["exports", "ember-lifeline/mixins/run", "js-admin-common/templates/components/billables/edit/expense-entry"], function (_exports, _run, _expenseEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Keep in mind there is a mapping between the componentAction="controllerAction"
   * {{billables/edit/expense removeExpense="removeExpense" expense=item expenseList=expenseList}}
   */
  var _default = Ember.Component.extend(_run.default, {
    tagName: 'tr',
    layout: _expenseEntry.default,
    showCut: false,
    lockRate: false,
    rateIsDisabled: false,
    reimbursementStatuses: [Ember.Object.create({
      display: 'Company Credit Card',
      value: 'Company Card'
    }), Ember.Object.create({
      display: 'Company Check',
      value: 'Company Check'
    }), Ember.Object.create({
      display: 'Employee',
      value: 'Employee'
    })],
    isLocked: Ember.computed('expense.reimbursementStatus', 'expense.isNew', function () {
      if (this.get('expense.isNew')) {
        return false;
      } else {
        return this.get('expense.reimbursementStatus') === 'Employee Reimbursed';
      }
    }),
    qtyChanged: Ember.observer('expense.qty', function () {
      this.debounceTask('updateField', 'qty', 500);
    }),
    rateChanged: Ember.observer('expense.rate', function () {
      this.debounceTask('updateField', 'rate', 500);
    }),
    billTypeObserver: Ember.on('init', Ember.observer('expense.billableType', 'lockRate', function () {
      if (this.get('lockRate')) {
        var rate = this.get('expense.billableType.rate');

        if (rate) {
          if (rate != this.get('expense.rate')) {
            // we check equality because rate is string and expense.rate is number
            this.set('expense.rate', rate);
          }

          this.set('rateIsDisabled', true);
        } else {
          this.set('rateIsDisabled', false);
        }
      }
    })),
    updateField: function updateField(fieldName) {
      var value = this.get('expense.' + fieldName);

      if (value) {
        value = this.get('expense.' + fieldName).toString();
        value = value.replace(/[^\d.]/g, '');
        value = value.match(/^\d+(\.\d+)?/);

        if (value && value.length > 0) {
          this.set('expense.' + fieldName, parseFloat(value[0]));
        }
      }
    },
    total: Ember.computed('expense.rate', 'expense.qty', function () {
      var rate = this.get('expense.rate');
      var qty = this.get('expense.qty');

      if (rate && qty) {
        return rate * qty;
      }
    }),
    actions: {
      removeExpense: function removeExpense(item) {
        this.sendAction('removeExpense', item);
      }
    }
  });

  _exports.default = _default;
});