define("js-admin-common/templates/components/subjects/edit-multiple-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d8aeu0ky",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/subjects/edit-multiple-row.hbs"
    }
  });

  _exports.default = _default;
});