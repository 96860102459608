define("js-admin-common/mixins/notifications-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    notificationBodyClass: 'notification-body',
    notificationTimeClass: 'notification-time',
    queryParams: ['type', 'dateRange'],
    type: null,
    dateRange: 'week',
    ordering: ['createdOn:desc'],
    orderedNotifications: Ember.computed.sort('notifications', 'ordering'),
    allNotifications: [],
    typeNames: [],
    notifications: Ember.computed('allNotifications.[]', 'type', function () {
      var type = this.type;

      if (type) {
        return this.allNotifications.filterBy('notificationType.name', type);
      } else {
        return this.allNotifications;
      }
    }),
    actions: {
      deleteItem: function deleteItem(notification) {
        notification.destroyRecord();
      },
      markAsUnread: function markAsUnread(notification) {
        notification.set('read', 0);
        notification.save();
      },
      dismissItem: function dismissItem(notification) {
        notification.set('read', 1);
        notification.save();
      },
      dismissAll: function dismissAll() {
        this.notifications.filterBy('read', 0).forEach(function (unseen) {
          unseen.set('read', 1);
          unseen.save();
        });
      }
    }
  });

  _exports.default = _default;
});