define("js-admin-common/mixins/subject-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Special object to hold information about the fields aligned one to another
   */
  var _default = Ember.Object.extend({
    // will this subject be added or updated against an existing record
    mode: 0,
    // the id of the existing subject record
    id: 0,
    // the id used to keep track of each record from the .csv file (to correctly match each line with the matches)
    pseudoId: 0,
    // the field data to be imported
    // this object will use the names that matche real subject properties
    data: {},
    // what was the final result when attempting to import this record
    result: '',
    // errors generated when attempting to process the import record
    errors: '',
    matches: [],
    selectedMatch: null
  });

  _exports.default = _default;
});