define('ember-component-focus/services/component-focus/focus-manager', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;

  var FOCUSABLE_TAGS = ['a', 'button', 'input', 'option', 'select', 'textarea'];

  exports['default'] = _ember['default'].Service.extend({
    _afterRenderResolver: null,
    _afterRenderPromise: null,
    _blurListener: null,
    _nextToFocus: null,
    _nextToReset: null,

    focusComponent: function focusComponent(component) {
      var child = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var el = findElToFocus(component, child);
      var isFocusable = el.hasAttribute('tabindex') || isDefaultFocusable(el);

      if (!isFocusable) {
        el.setAttribute('tabindex', -1);
      }

      el.focus();

      // Done after `el.focus()` to prevent the `blur` handler from triggering
      // too early.
      if (!isFocusable) {
        this.set('_nextToReset', el);
      }

      return el;
    },

    focusComponentAfterRender: function focusComponentAfterRender(component) {
      var _this = this;

      var child = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var afterRenderPromise = this.get('_afterRenderPromise');

      this.set('_nextToFocus', { component: component, child: child });

      if (!afterRenderPromise) {
        afterRenderPromise = new RSVP.Promise(function (resolve) {
          _this.set('_afterRenderResolver', resolve);
        });
        this.set('_afterRenderPromise', afterRenderPromise);
      }

      run.scheduleOnce('afterRender', this, '_afterRenderCallback');
      return afterRenderPromise;
    },

    init: function init() {
      // Don't try to attach events in server side environments.
      if (typeof document === 'undefined') {
        return;
      }

      var blurListener = this._handleBlur.bind(this);
      document.body.addEventListener('blur', blurListener, true);
      this.set('_blurListener', blurListener);
    },

    willDestroy: function willDestroy() {
      var blurListener = this.get('_blurListener');

      if (blurListener) {
        document.body.removeEventListener('blur', blurListener, true);
      }
    },

    _afterRenderCallback: function _afterRenderCallback() {
      var resolver = this.get('_afterRenderResolver');

      var _get = this.get('_nextToFocus');

      var component = _get.component;
      var child = _get.child;

      var focusedEl = this.focusComponent(component, child);

      this.set('_nextToFocus', null);
      this.set('_afterRenderPromise', null);
      this.set('_afterRenderResolver', null);

      if (typeof resolver === 'function') {
        resolver(focusedEl);
      }
    },

    _handleBlur: function _handleBlur() {
      var elToReset = this.get('_nextToReset');
      if (elToReset) {
        elToReset.removeAttribute('tabindex');
        this.set('_nextToReset', null);
      }
    }
  });

  function findElToFocus(component, child) {
    if (!child) {
      return component.element;
    }

    var isChildString = typeof child === 'string';
    if (!isChildString && child.hasOwnProperty('length')) {
      // Child is probably a jQuery object, so unwrap it.
      child = child[0];
    }

    if (isChildString) {
      var childEl = component.element.querySelector(child);
      if (!childEl) {
        throw new Error('No child element found for selector \'' + child + '\'');
      }
      return childEl;
    }

    return child;
  }

  function isDefaultFocusable(el) {
    var tagName = el.tagName.toLowerCase();
    return FOCUSABLE_TAGS.indexOf(tagName) > -1;
  }
});
/* Copyright 2015 LinkedIn Corp. Licensed under the Apache License, Version
 * 2.0 (the "License"); you may not use this file except in compliance with
 * the License. You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */