define("js-admin-common/mixins/subjects-import-route", ["exports", "js-admin-common/mixins/sorted-field"], function (_exports, _sortedField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentMatter: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        matter: this.currentMatter.get('matter')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var order = 2;
      var allowedFields = this.currentMatter.get('matterFields').sortBy('weight').map(function (field) {
        return _sortedField.default.create({
          fieldName: field.get('value'),
          fieldDisplay: field.get('display'),
          order: order++
        });
      });
      allowedFields.pushObject(_sortedField.default.create({
        fieldName: 'id',
        fieldDisplay: 'ID (use to update subjects)',
        order: 1
      }));
      controller.set('matterFields', allowedFields); // Required Fields

      var requiredFields = {
        last_name: 'Last Name',
        first_name: 'First Name',
        subject_type: 'Subject Type' // 'member_id': 'Member ID',
        // 'notes': 'Notes',
        // this are specific for plaintiff
        // 'injured',
        // 'brought_suit',

      };

      for (var field in requiredFields) {
        var matterField = controller.matterFields.findBy('fieldName', field);

        if (!matterField) {
          controller.matterFields.pushObject(_sortedField.default.create({
            fieldName: field,
            fieldDisplay: requiredFields[field],
            order: order++,
            isRequired: true
          }));
        } else {
          matterField.set('isRequired', true);
        }
      }

      var defaultOrder = {
        subject_type: 1,
        location: 2,
        first_name: 3,
        last_name: 4,
        member_id: 5,
        court_juror: 6,
        court_panel: 7,
        id: 8
      };
      order = 1;
      controller.matterFields.forEach(function (field) {
        if (Ember.isPresent(defaultOrder[field.get('fieldName')])) {
          field.set('order', defaultOrder[field.get('fieldName')]);
          order = defaultOrder[field.get('fieldName')] > order ? defaultOrder[field.get('fieldName')] : order;
        }
      });
      order++;
      controller.matterFields.forEach(function (field) {
        if (!Ember.isPresent(defaultOrder[field.get('fieldName')])) {
          field.set('order', order++);
        }
      });
    }
  });

  _exports.default = _default;
});