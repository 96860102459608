define("ember-steps/helpers/validate-transition", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateTransition = validateTransition;
  _exports.default = void 0;

  function validateTransition(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        transition = _ref3[0];

    var validator = _ref2.with;
    return function () {
      return new Ember.RSVP.Promise(function (resolve) {
        validator(resolve);
      }).then(function () {
        transition();
      });
    };
  }

  var _default = Ember.Helper.helper(validateTransition);

  _exports.default = _default;
});