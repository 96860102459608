define("js-admin-common/utils/case-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * create a helpful object to describe objects where a child may or may not exist
   * complete with some useful calculations to drive logic on workflow pages
   *
   * could be used to describe any parent child relationship
   * a few examples....
   * workflow >> matter_has_workflow
   * matter_has_workflow >> simple_workflow
   *
   */
  var _default = Ember.Object.extend({
    // the parent record
    parent: null,
    // the child record
    child: null,

    /**
     * is a workflow enabled for this case
     */
    isEnabled: Ember.computed('child', function () {
      if (Ember.isEmpty(this.child)) {
        return false;
      } else {
        return true;
      }
    }),

    /**
     * inverse of isEnabled
     * used for if statements in template
     */
    isDisabled: Ember.computed('isEnabled', function () {
      var isEnabled = this.isEnabled;
      return !isEnabled;
    })
  });

  _exports.default = _default;
});